import ExternalApi from "./ExternalApi";
import SecretNavigation from "./SecretNavigation"
import SecretUploader from "./SecretUploader"


function Interface() {
    return (
        <div>
            <SecretNavigation></SecretNavigation>
            <SecretUploader></SecretUploader>
        </div>
    );
}

export default Interface;