import React, { Component } from 'react'
import { Navbar, Nav, Button, Modal } from 'react-bootstrap';
import Login from './Login'
import LoginButton from './LoginButton';

export class Navigation extends Component {





  render() {
    return (
      <div>
        <Navbar style={{ backgroundColor: "#1D1D1D" }} collapseOnSelect expand="lg" variant="dark">
          <Navbar.Brand href="#home"><b>Removeit.io</b></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">

            </Nav>
            <Nav>
              <Nav.Link href="#start"><b>Start</b></Nav.Link>

              <Nav.Link href="#features"><b>Features</b></Nav.Link>

              <Nav.Link href="#pricing"><b>Pricing </b></Nav.Link>
              <Nav.Link href="#examples"><b>Examples </b></Nav.Link>
              <LoginButton></LoginButton>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </div>


    )
  }
}

export default Navigation
