
import robbe from '../assets/robbe.mp4'
import robberemoved from '../assets/robberemoved.mp4'

import coffee from '../assets/coffee.mp4'
import coffeeremoved from '../assets/coffeeremoved.mp4'


import VideoSlider from './VideoSlider'

import { Container, Row, Col,  } from 'react-bootstrap';


function Examples() {



    return (
        <div style={{ backgroundColor: "#111827" }}>
                <h1 style = {{color: "#6B7280", textAlign:"center", paddingTop:"10vh"}}><b>Examples</b></h1>

      <Container style = {{padding:"10vh"}}>
        <Row>
        <Col md={6}>
    <VideoSlider style = {{width:"100%", }} srcfirst = {robbe} srcsecond = {robberemoved}></VideoSlider>
    </Col>


      <Col md = {6}>
    <VideoSlider style = {{width:"100%"}} srcfirst = {coffee} srcsecond = {coffeeremoved}></VideoSlider>
    </Col>
    </Row>
  
    </Container>

        </div>
    );
}

export default Examples;