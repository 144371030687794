import React, { useEffect, useState } from "react";
import { Navbar, Nav, Button } from 'react-bootstrap';
import { axiosInstance } from './axiosInstance'
import {Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";


import { useAuth0 } from "@auth0/auth0-react";



function getCreditCount() {
    

    return axiosInstance.post(
        'getCreditCount'
    )
}


function SecretNavigation(props) {

    const [Credits, setCredits] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const { user, context, callback } = useAuth0();

    useEffect(() => {
        axiosInstance.post("getCreditsFromApi", { "user_id": user.sub }).then(res => {
            setCredits(res.data['credits'])
        })
    }, []);
    return (
        <div>
            <Navbar style={{ backgroundColor: "#1D1D1D" }} collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand href="#home" style={{ color: "#D1D1D1" }} ><b>Removeit.io</b></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    <Nav>

                        <Navbar style={{ color: "#D1D1D1" }}> {Credits} Credits</Navbar>
                        <Navbar>
                        <Link to="/secretsettings">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D1D1D1" class="bi bi-gear-fill" viewBox="0 0 16 16">
                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                            </svg>
                            </Link>
                            </Navbar>
                        <LogoutButton></LogoutButton>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div >
    );
}

export default SecretNavigation;