import greenicon from "../assets/greenicon.png"
import HakenIcon from "./HakenIcon"
import SignupButton from './SignupButton';
import React, { useState } from 'react';
import paypalicons from "../assets/paypalicons.png"

function PricingTable(props) {

    const [credits, setCredits] = useState(600);


    return (
        <div>
            <h1 style = {{color:"#6B7280"}}>Service currently not available :/</h1> <br/>

            <h2 style = {{color:"#6B7280"}}>Use the free <a href="https://edit-videos-online.com" rel="dofollow">online video editor</a> to edit your videos online for free. Add text to video online without registration with <a href="https://edit-videos-online.com" rel="dofollow">this free online video editor</a></h2>
        </div>)
    {/*
        <div style={{ backgroundColor: "#1A1C29", color: "#FFFFFF", border: "3px solid white", borderRadius: "4vh", boxShadow: "5px 5px 15px 5px #000000", padding: "1vh" }}>
            <div style={{ display: "flex", alignItems: "center" }}>


                <div>

                    <div style={{ ...(credits == 60 ? { backgroundColor: "#8000FF" } : { backgroundColor: "white" }), padding: "3vh", borderRadius: "1vh", display: "flex" , margin: "2vh"}} onClick={() => {
                        setCredits(60);
                    }}>

                        
                    <label style={{ ...(credits == 60 ? { color: "white" } : { color: "gray" })}}>
                        <input
                            type="radio"
                            checked={credits == 60}
                        />
                       &nbsp;1 minute background removal  4,99$
                    </label>
                    </div>


                    <div style={{ ...(credits == 180 ? { backgroundColor: "#8000FF" } : { backgroundColor: "white" }), padding: "3vh", borderRadius: "1vh", display: "flex",   margin: "2vh"}} onClick={() => {
                        setCredits(180);
                    }}>

                        
                    <label style={{ ...(credits == 180 ? { color: "white" } : { color: "gray" })}}>
                        <input
                            type="radio"
                            checked={credits == 180}
                        />
                       &nbsp;3 minutes background removal  8,99$
                    </label>
                    </div>

                    <div style={{ ...(credits == 600 ? { backgroundColor: "#8000FF" } : { backgroundColor: "white" }), padding: "3vh", borderRadius: "1vh", display: "flex" ,  margin: "2vh"}} onClick={() => {
                        setCredits(600);
                    }}>

                        
                    <label style={{ ...(credits == 600 ? { color: "white" } : { color: "gray" })}}>
                        <input
                            type="radio"
                            checked={credits == 600}
                        />
                       &nbsp;10 minutes background removal  24,99$
                    </label>
                    </div>

                    


                </div>

                <div style={{ padding: "2vh", borderLeft: "1px solid gray", height: "auto" }}>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2vh" }}>
                        <HakenIcon style={{ border: "3px solid red" }}></HakenIcon>
                        <p>&nbsp;&nbsp;Audio support (mp4)</p> <p style={{ color: "#87888D" }}></p>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2vh" }}>
                        <HakenIcon style={{ border: "3px solid red" }}></HakenIcon>
                        <p>&nbsp;&nbsp;1080p supported</p> <p style={{ color: "#87888D" }}></p>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2vh" }}>
                        <HakenIcon style={{ border: "3px solid red" }}></HakenIcon>
                        <p>&nbsp;&nbsp;GIF download / Mp4 download (audio)</p> <p style={{ color: "#87888D" }}></p>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2vh" }}>
                        <HakenIcon style={{ border: "3px solid red" }}></HakenIcon>
                        <p>&nbsp;&nbsp;Not watermarked</p> <p style={{ color: "#87888D" }}></p>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2vh" }}>
                        <HakenIcon style={{ border: "3px solid red" }}></HakenIcon>
                        <p>&nbsp;&nbsp;Other formats coming soon!</p> <p style={{ color: "#87888D" }}></p>
                    </div>

                </div>
            </div>


            <div style={{ paddingTop: "4vh" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <SignupButton plan={"credits" + credits}></SignupButton>
                    <img style={{ width: "50%", paddingTop: "4vh" }} src={paypalicons}></img>
                </div>

            </div>

        </div>
                */}



}

export default PricingTable;