import React from 'react';

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import LandingPage from "./components/LandingPage"
import Secret from "./components/Secret"
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import Widerruf from './components/Widerruf';
import PayPalPayment from './components/PayPalPayment';




function App(props) {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      

      <BrowserRouter>
      <Switch>
       

       

        
        
        <Route path = "/credits40">
          <PayPalPayment amount = "3.99" credits = {40}></PayPalPayment>
          </Route>

          <Route path = "/credits50">
          <PayPalPayment amount = "4.49" credits = {50}></PayPalPayment>
          </Route>
          <Route path = "/credits60">
          <PayPalPayment amount = "4.99" credits = {60}></PayPalPayment>
          </Route>

          <Route path = "/credits180">
          <PayPalPayment amount = "8.99" credits = {180}></PayPalPayment>
          </Route>

          <Route path = "/credits600">
          <PayPalPayment amount = "24.99" credits = {600}></PayPalPayment>
          </Route>

          <Route path = "/credits1200">
          <PayPalPayment amount = "39.99" credits = {1200}></PayPalPayment>
          </Route>

          <Route path = "/credits3600">
          <PayPalPayment amount = "69.99" credits = {3600}></PayPalPayment>
          </Route>


        <Route path="/impressum">
          <Impressum/>
        </Route>

        <Route path="/datenschutz">
          <Datenschutz/>
        </Route>

        <Route path="/widerruf">
          <Widerruf/>
        </Route>

        <Route path="/">
        {isAuthenticated ? <Secret /> : <LandingPage />}
        </Route>
        </Switch>

        
      </BrowserRouter>


    </div>
  );
}

export default App;