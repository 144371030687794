import React, { useState, useEffect } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { saveAs } from 'file-saver'
import { DropdownButton, Dropdown, Button, ButtonGroup, Spinner, Container, Row, Col } from 'react-bootstrap';
import { axiosInstance } from './axiosInstance';
import { HexColorPicker } from "react-colorful";
import Dropzone from 'react-dropzone';
import axios from 'axios';



function DoneVideo(props) {

    const videourl = process.env.REACT_APP_API_URL + props.url
    const [videoavailable, setVideoavailable] = useState(true)
    const [color, setColor] = useState("transparent");
    const [exportLoading, setExportLoading] = useState(false)
    const [format, setFormat] = useState("gif")
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [imageArray, setImageArray] = useState(null)

    useEffect(() => {
        axiosInstance.get('https://api.pexels.com/v1/curated?page=2&per_page=40', {
            headers: {
                'Authorization': '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c'
            }
        }).then((res) => {
          
            setImageArray(res.data)

 

        });

    }, [])

    var imageUrls = ['static/B16O8YQZ22ZP5VHJ.jpg', 'static/YWGRV1M6FUZYNYT2.jpg', 'static/3ZIGWGX193QFLRR6.jpg'];


  

    const downloadImage = () => {
        setExportLoading(true)
        axiosInstance.post("export", { "color": color, "seed": props.url, "format": format, "backgroundImage": backgroundImage }).then(res => {
            saveAs(process.env.REACT_APP_API_URL + res.data['url'], 'removedbackground.gif')
            setExportLoading(false)
        })
        // Put your image url here.
    }

    const exportvideo = () => {

    }

    function sendImage(image) {


        
        var formData = new FormData();
        formData.append("file", image[0]);
        axiosInstance.post('uploadImage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
          

            setBackgroundImage(res.data)
            console.log(backgroundImage)

        });


    }

    return (
        <div style={{}}>
            {console.log(videoavailable)}

            {
                videoavailable ? <div>

                    <Container >
                        <Row>
                            <Col sm={8}><img alt="" style={{ backgroundColor: color, width: "100%", backgroundImage: `url(${process.env.REACT_APP_API_URL + backgroundImage})`, backgroundSize: 'cover'}} src={process.env.REACT_APP_API_URL + props.url} onError={(e) => { e.target.onerror = null; setVideoavailable(false) }} type="video/gif" /></Col>
                            <Col sm={4}><div>

                                <div style = {{display: "flex", marginBottom: "2vh", width: "100%"}}>
                        {   false?  
                            imageArray.map(function(image){
                                return <img style = {{width: "100%"}}  src = {image.src.original}></img>;
                              }) : <div></div>
                            
                        }
                        </div>

                            <Dropzone maxSize={52428800} onDrop={acceptedFiles => sendImage(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        


                                        <Button style = {{marginBottom: "2vh", width: "100%"}}>Upload your own Image</Button>

                                    </div>
                                </section>

                            )}
                        </Dropzone>
                        <div style = {{display: "flex", marginBottom: "2vh", width: "100%"}}>
                        {
                            imageUrls.map(function(url){
                                return <img style = {{width: "100%"}} onClick = {() => setBackgroundImage(url)} src = {process.env.REACT_APP_API_URL + url}></img>;
                              })
                            
                        }
                        </div>


                                <HexColorPicker style={{ width: "100%" }} color={color} onChange={(c) => {setColor(c); setBackgroundImage(null)}} />

                                <Dropdown style = {{width:"100%", marginTop: "2vh"}} as={ButtonGroup}>
                                <Button style={{ width: "100%", }} onClick={downloadImage}>
                                    {
                                        exportLoading ? <Spinner animation="border" size="sm" aria-hidden="true" /> : <div></div>
                                    }


                                    Export as {format}</Button>


                                    <Dropdown.Toggle split/>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1" onClick={() => setFormat("gif")}>Gif</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2" onClick={() => setFormat("mp4")}>Mp4</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                               
                            </div></Col>
                        </Row>

                    </Container>
                </div> : <div></div>



            }



        </div>
    );
}

export default DoneVideo;