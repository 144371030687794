import { Container, Row, Col } from 'react-bootstrap';


function Footer(){
return(
    
    <div style={{backgroundColor:"#1F2937"}}>
    

        <Container style ={{paddingTop:"2vh"}}>
   
            <Row style = {{color: "#111827"}}>
                
                <Col align="center"><p style={{color:"#4B5563", fontSize:"10px"}}><a style ={{color:"gray"}} href="/impressum">Impressum</a></p></Col>
                <Col align="center"><p style={{color:"#4B5563", fontSize:"10px"}}><a style ={{color:"gray"}} href="/datenschutz">Datenschutzerklärung</a></p></Col>
                <Col align="center"><p style={{color:"#4B5563", fontSize:"10px"}}><a style ={{color:"gray"}} href="/widerruf">Widerruf</a></p></Col>

            </Row>
        </Container>
    </div>
)

}

export default Footer;