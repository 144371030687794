import { Container, Row, Col } from 'react-bootstrap';
import nogreenscreen from '../../src/assets/nogreenscreen.png'
import VideoSlider from './VideoSlider';
import hand from "../../src/assets/hand.mp4"
import handremoved from "../../src/assets/handremoved.mp4"



function Features() {
    return (
        <div style={{ backgroundColor: "#1F2937" }}>
            <Container>
                
                <Row style = {{display: 'flex', justifyContent: 'center', padding: "3rem"}}><h1 style={{ color: "#6B7280" }} >Video background remover features</h1></Row>
                <Row style = {{paddingBottom: "4rem"}}>
                    <Col  lg={true} style={{ color: "#6B7280" }}>
                        <h1>Remove video background</h1>
                        <p> 1. Upload a mp4 video <br />
                            2. Our servers process the video and remove the
                            background automatically <br />
                            3. You can download a gif with the removed background</p>
                    </Col>
                    <Col>
                    <VideoSlider style = {{maxWidth:"100%"}} srcfirst = {hand} srcsecond = {handremoved}></VideoSlider>
                    </Col>
                    
                </Row>
                <Row style = {{paddingBottom: "4rem"}}>
                    <Col lg={true}><img style={{maxWidth:"100%"}}src={nogreenscreen} alt="remove video background without greenscreen" /></Col>
                    <Col lg={true} style={{ color: "#6B7280" }}>
                        <h1>No Greenscreen required</h1>
                        <p>
                            Remove the background of your videos without a green screen.
                            Our AI detects the background and removes it automatically
                            Simply upload a video and download the result.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Features;
