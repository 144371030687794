import React from "react";
import { withRouter } from "react-router-dom";
import Interface from './Interface'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";


function Secret({ ...rest }) {
  return (
    <div style={{ backgroundColor: "#111827", }}>

      
          <Interface></Interface>
          
    </div>
  );
}

export default withAuthenticationRequired(Secret);