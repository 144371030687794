import { ReactCompareSlider } from 'react-compare-slider';
import ReactPlayer from 'react-player'
import React, { useState} from 'react';



function VideoSlider(props) {


    const [loadedfirst, setLoadedfirst] = useState(false);
    const [loadedsecond, setLoadedsecond] = useState(false);

    
    



    return (


        <div>

            
            <video style={{width: "20%",  display:"none"}} src={props.srcfirst} onCanPlayThrough={() => {setLoadedfirst(true)}} > </video>
            <video style={{width: "20%", display:"none"}} src={props.srcsecond} onCanPlayThrough={() => {setLoadedsecond(true)}} > </video>



             <ReactCompareSlider 
                itemOne={<ReactPlayer loop={true} muted playing={loadedfirst&&loadedsecond} url={props.srcfirst} type="video/gif" />}
                itemTwo={<ReactPlayer loop={true} muted playing={loadedfirst&&loadedsecond} url={props.srcsecond} type="video/gif" />}
            /> 
              
           
           




        </div>
    );
}

export default VideoSlider;