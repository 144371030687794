import { Container, Row, Col } from 'react-bootstrap';
import howtoone from '../../src/assets/howtoone.png'
import howtotwo from '../../src/assets/howtotwo.png'
import howtothree from '../../src/assets/howtothree.png'




function Howto() {
    return (

        <div style={{ backgroundColor: "#111827" }}>


            <Container>

                <Row style={{ display: 'flex', justifyContent: 'center', padding: "3rem" }}><h1 style={{ color: "#6B7280" }} >Video background remover features</h1></Row>
                <Row style={{ paddingBottom: "4rem" }}>
                    <Col lg={true} style={{ color: "#6B7280" }}>
                        <h1>Step one</h1>
                        <h3>Choose a file you want its background
                            removed from.
                            You can either drag and drop
                            Your mp4 file or select them manually by
                            clicking the upload button</h3>
                    </Col>
                    <Col>
                        <img style={{ maxWidth: "100%" }} src={howtoone}></img>
                    </Col>

                </Row>

                <Row style={{ paddingBottom: "4rem" }}>
                    <Col lg={true} style={{ color: "#6B7280" }}>
                        <h1>Step two</h1>
                        <h3> Once your video is uploaded our servers
                            start processing it and erase the
                            background from the video. This process
                            can take upto two minutes</h3>
                    </Col>
                    <Col>
                        <img style={{ maxWidth: "100%" }} src={howtotwo}></img>
                    </Col>

                </Row>

                <Row style={{ paddingBottom: "4rem" }}>
                    <Col lg={true} style={{ color: "#6B7280" }}>
                        <h1>Step three</h1>
                        <h3> Once our servers are done removing the
                            background of your video you will see the
                            result as a gif. You can then proceed to
                            download it</h3>
                    </Col>
                    <Col>
                        <img style={{ maxWidth: "100%" }} src={howtothree}></img>
                    </Col>

                </Row>

            </Container>
        </div>
    )

}

export default Howto;