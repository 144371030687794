import Dropzone from 'react-dropzone'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { axiosInstance } from './axiosInstance'
import React, { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import removed from '../assets/removed.mp4'
import notremoved from '../assets/notremoved.mp4'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DoneVideo from './DoneVideo';
import VideoSlider from './VideoSlider'
import { render } from '@testing-library/react';
import PayPalPayment from './PayPalPayment';
import PricingTable from './PricingTable';



function SecretUploader() {

    const [videourl, setVideourl] = useState(null);
    let [loading, setLoading] = useState(false);
    const [videoUrls, setVideoUrls] = useState({ "url0": "test" })
    const [tooLong, setTooLong] = useState(0);

    const { getAccessTokenSilently } = useAuth0();
    const { user, context, callback } = useAuth0();

    function updateAppMetaData() {
        axiosInstance.post("getAppMetaData", { "user_id": user.sub }).then(res => {
            setVideoUrls(res.data)
        })
    }

    useEffect(() => {
        updateAppMetaData();

    }, []);







    const onHide = () => setTooLong(0)


    const sendVideo = async (video) => {
        var token = await getAccessTokenSilently();
        /*axiosInstance.post('createURL', {"token": token, "user_id": user.sub}, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setVideourl(axiosInstance.defaults.baseURL + res.data)
            setLoading(false)
        });*/
        var formData = new FormData();
        formData.append("file", video[0]);
        formData.append("user_id", user.sub)


        setLoading(true)
        axiosInstance.post('rvbprotected', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            try {
                if ('laenge' in res.data) {
                    setTooLong(res.data['laenge'])
                }
            }catch(err){

            }
            
            setVideourl(axiosInstance.defaults.baseURL + res.data)
            setLoading(false)
            updateAppMetaData()
        });




    }

    return (
        <div>








            <Modal animation={false} show={tooLong != 0} onHide={onHide} style={{}}>
                {<h2>Your don't have enough credits</h2>}
                <PricingTable></PricingTable>
            </Modal>




            <Container >
                <Row>
                    <Col md={6}>
                        {loading ? <div><h1>This will take some time. Come back later when your results are available</h1><PuffLoader color={"#6B7280"} loading={loading} size={200} /> </div>: videourl != null ? <div><img alt="" style={{ width: "100%", borderRadius: "20px" }} src={videourl} type="video/gif" /> <Button href={videourl}
                            color="transparent"
                            target="_blank"
                        >Download gif</Button></div> : <VideoSlider style={{ width: "100%" }} srcfirst={notremoved} srcsecond={removed}></VideoSlider>
                        }

                    </Col>
                    <Col md={6} style={{}}>
                        <Dropzone onDrop={acceptedFiles => sendVideo(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div style={{ backgroundColor: "#262626", borderRadius: "20px", borderColor: "#656870", borderWidth: "2px", borderStyle: "dashed" }}>
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <h3 style={{ color: "#D1D1D1", padding: "3vh" }}>Drag and Drop your video</h3>
                                                <h3 style={{ color: "#D1D1D1", paddingBottom: "3vh" }} >or</h3>
                                                <div style={{ paddingBottom: "3vh" }}>
                                                    <Button variant="primary">Upload your video</Button>{' '}

                                                </div>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <h6 style={{ color: "#D1D1D1" }} >1080p</h6>
                                                <h6 style={{ color: "#D1D1D1" }} >not watermarked</h6>
                                                <h6 style={{ color: "#D1D1D1" }} >gif download</h6>
                                                <h6 style={{ color: "#D1D1D1", paddingBottom: "3vh" }} >png sequence coming soon</h6>
                                            </div>



                                        </div>

                                    </div>
                                </section>

                            )}
                        </Dropzone>

                    </Col>
                </Row>
            </Container>

            {Object.keys(videoUrls).map(function (keyName, keyIndex) {
                return (
                    <div style={{ display: "flex", flexDirectio: "row",   paddingBottom: "5vh"}}>
                        <DoneVideo url={videoUrls[keyName]} style={{ display: "inlineBlock",}}></DoneVideo>
                    </div>
                )
            })}


        </div>
    );
}

export default SecretUploader;