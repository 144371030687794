import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Ap from './Ap'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth0Provider } from '@auth0/auth0-react';


ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-sv4a9m88.us.auth0.com"
      clientId="RSfzSsPaXTXJAsjMT3BFqahz6jaG7Xed"
      redirectUri={window.location.origin}
      audience="https://api.removei.io/"
    >
    <App />
  </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
