function Widerruf() {
    return (
        <div>
            <p>
                	
Widerrufsbelehrung
Widerrufsrecht

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von GrÃ¼nden diesen Vertrag zu widerrufen.

Die Widerrufsfrist betrÃ¤gt vierzehn Tage ab dem Tag des Vertragsabschlusses.

Um Ihr Widerrufsrecht auszuÃ¼ben, mÃ¼ssen Sie uns (Julian RiÃŸ, MÃ¼nchen 80939 Paul-Hindemith-Allee 4 201, 01623785670) mittels einer eindeutigen ErklÃ¤rung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) Ã¼ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie kÃ¶nnen dafÃ¼r das beigefÃ¼gte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.

Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung Ã¼ber die AusÃ¼bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
Folgen des Widerrufs

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlieÃŸlich der Lieferkosten (mit Ausnahme der zusÃ¤tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, gÃ¼nstigste Standardlieferung gewÃ¤hlt haben), unverzÃ¼glich und spÃ¤testens binnen vierzehn Tagen ab dem Tag zurÃ¼ckzuzahlen, an dem die Mitteilung Ã¼ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. FÃ¼r diese RÃ¼ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprÃ¼nglichen Transaktion eingesetzt haben, es sei denn mit Ihnen wurde ausdrÃ¼cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser RÃ¼ckzahlung Entgelte berechnet.

Haben Sie verlangt, dass die Dienstleistungen wÃ¤hrend der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der AusÃ¼bung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.

            </p>
        </div>);
}

export default Widerruf;