import React, { Component } from 'react'
import HeroUploader from './HeroUploader'

export class Hero extends Component {
    render() {
        return (
            <div>
                <div style={{ backgroundColor: "#111827" }}>

                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center", paddingTop: "5vh", paddingLeft: "5vh", paddingRight: "5vh"}}>
                        <h1 style = {{color: "#6B7280"}}><b>Remove the background of your videos<br/> online in seconds</b></h1>
                    </div>
                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center", paddingBottom: "5vh", paddingLeft: "5vh", paddingRight: "5vh"}}>
                        <h5 style = {{color: "#6B7280"}}>Our AI will remove the background of your videos for you - it’s free and super easy to use!</h5>
                    </div>

                    <div style={{ paddingBottom: "5vh" }}>
                        <HeroUploader></HeroUploader>
                    </div>
                </div>


            </div >
        )
    }
}

export default Hero
