import React, {useState} from 'react';
import { Container, Row, Col, Button, Modal, ButtonGroup, Input } from 'react-bootstrap';
import greenicon from "../assets/greenicon.png"
import HakenIcon from "./HakenIcon"
import PricingTable from './PricingTable';
import SignupButton from './SignupButton';



function Pricing(props) {



    return (
        <div>
            <Container fluid>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: "flex-end", paddingTop: "10vh", paddingBottom: "10vh" }}>

                    <Col md={6}>
                        <PricingTable></PricingTable>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Pricing;