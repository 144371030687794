import React from 'react'
import Navigation from './Navigation'
import Pricing from './Pricing'
import Hero from './Hero'
import { withRouter } from "react-router-dom";
import Examples from './Examples';
import Footer from './Footer';
import Features from './Features';
import Howto from './Howto'
import { HashLink } from 'react-router-hash-link';






function LandingPage() {

    /*const handleAcceptCookie = () => {

        ReactGA.initialize('G-B3ZCLVWHW5');
        
        ReactGA.pageview(window.location.pathname + window.location.search);


    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");

    };*/

    








    return (




        < div >
        <HashLink to="#pricing"></HashLink>
        <HashLink to="#features"></HashLink>
        <HashLink to="#examples"></HashLink>
        <HashLink to="#start"></HashLink>



            <Navigation></Navigation>
            <div id = "start">
            <Hero></Hero>
            </div>
            <div id = "features">
            <Features></Features>
            </div>

            <div id = "examples">
            <Examples></Examples>
            </div>
            
            <div style={{ backgroundColor: "#1F2937" }} id = "pricing">
            <Pricing></Pricing>
            </div>
            <Howto></Howto>
            <Footer></Footer>
        </div >
    )
}


export default withRouter(LandingPage);