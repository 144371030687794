import React, { useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { axiosInstance } from './axiosInstance'

import { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

import removed from '../assets/removed.mp4'
import notremoved from '../assets/notremoved.mp4'

import VideoSlider from './VideoSlider'

import Pricing from './Pricing'
import PricingTable from './PricingTable';
import DoneVideo from './DoneVideo';

import { saveAs } from 'file-saver'






function HeroUploader() {



    const [progress, setProgress] = useState(0);
    const [videourl, setVideourl] = useState(null);
    let [uploading, setUploading] = useState(false);
    let [loading, setLoading] = useState(false);


    const downloadImage = () => {
        saveAs(videourl, 'removedbackground.gif') // Put your image url here.
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(progress < 92){
            setProgress(progress + 1)
            }
          }, 3000);
          return () => clearInterval(interval);
    });

    function sendVideo(video) {


        setLoading(true)        
        setProgress(0)
        const a = {}
        var formData = new FormData();
        formData.append("file", video[0]);
        axiosInstance.post('rvb', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((resolution) => {
          

            //setProgress(100)
            

            setVideourl(resolution.data)

            //setProgress(0)
            setLoading(false)

 

        });


    }

    return (
        <div>

            <Container>

                
                <Row>
                    <Col sm = {6}>
                        
                    {/*loading ? <ProgressBar  animated now={progress}/> : (videourl != null ? <div><DoneVideo url = {videourl}/> </div>: <VideoSlider srcfirst={notremoved} srcsecond={removed}></VideoSlider>)*/}

                        
                        
                       
                    </Col>
                    <Col sm = {6}>
                        
                    {videourl != null && !loading ? <Col><h4 style = {{color: "#FFFFFF"}}>It looks like your video is exceeding our time / quality limit. Upgrade to the PRO version right now!</h4><PricingTable></PricingTable></Col> : (<div> { loading? <PricingTable></PricingTable>:<Dropzone maxSize={524288000} accept="video/mp4" onDrop={acceptedFiles => sendVideo(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div style={{ backgroundColor: "#262626", borderRadius: "20px", borderColor: "#656870", borderWidth: "2px", borderStyle: "dashed" }}>
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <h3 style={{ color: "#D1D1D1", padding: "3vh" }}>Drag and Drop your video</h3>
                                                <h3 style={{ color: "#D1D1D1", paddingBottom: "3vh" }} >or</h3>
                                                <div style={{ paddingBottom: "3vh" }}>
                                                    <Button variant="primary">Upload your video</Button>{' '}

                                                </div>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <h6 style={{ color: "#D1D1D1" }} >5 seconds free</h6>
                                                <h6 style={{ color: "#D1D1D1" }} >720p</h6>
                                                <h6 style={{ color: "#D1D1D1" }} >not watermarked</h6>
                                                <h6 style={{ color: "#D1D1D1" }} >upload mp4 videos</h6>
                                                <h6 style={{ color: "#D1D1D1", paddingBottom: "3vh" }} >gif download</h6>
                                            </div>



                                        </div>

                                    </div>
                                </section>

                            )}
                        </Dropzone>}</div>)} 
                        

                    </Col>
                </Row>
            </Container>


        </div>
    )
}


export default HeroUploader
